import CustomSourceFlowText from "@/components/Shared/CustomSourceflowText";
import useCompanies from "@/hooks/useCompanies";
import Link from "next/link";
import useLocale from "@/hooks/useLocale";
import localizePath from "@/functions/localizePath";
import Marquee from "react-marquee-slider";
import React from "react";
import CustomSourceflowText from "@/components/Shared/CustomSourceflowText";

export default function CompanySlider({
  title,
  description = "",
  isFooterExists,
  cta_button_title_1 = "Learn more",
  cta_button_title_2 = "Learn more",
  path = "company",
}) {
  const locale = useLocale();
  const companies = useCompanies();
  const localeToRegionMap = {
    en: 'a7133cb4-5b75-451c-8e75-cd0790b4dc1f',
    de: 'eb2329fa-d657-4dee-95c5-486e306ba477',
    usa: '7bb05825-298a-4512-b0ec-2925f25ca0c6'
  };
  const filteredCompaniesByLocale = companies.filter(company => 
    company.region.includes(localeToRegionMap[locale])
  );
  const companyItemsRow1 = filteredCompaniesByLocale.slice(0, 5);
  const companyItemsRow2 = filteredCompaniesByLocale.slice(5, 9);

  return (
    <div className="container w-full py-16 sm:pt-[117px] sm:pb-[93px] flex flex-col justify-center gap-10">
      <div className="text-3xl sm:text-[40px] xl:text-[50px]/[60px] text-center flex flex-col gap-5">
        <span>
          <CustomSourceFlowText
            title={title}
            path={`dynamic1.${path}`}
            locale={locale}
          />
          <span className="text-blue">.</span>
        </span>
        {description && (
          <div className="flex justify-center">
            <span className="w-full lg:w-[50%] text-base sm:text-[20px]/[30px] md:text-[30px]/[40px] lg:text-[30px]/[40px]">
              <CustomSourceFlowText
                title={description}
                path={`dynamic1.${path}.text1`}
                locale={locale}
              />
            </span>
          </div>
        )}
      </div>
      <div className="pt-[60px]">
        <div className="mb-10 sm:mb-[76px]">
          <Marquee>
            {companyItemsRow1.map((item, index) => (
              <div key={index}>
                <div className="flex justify-center items-center item lg:ps-[100px] lg:pe-[100px] ps-[30px] pe-[30px]">
                  <img
                    src={item.logo}
                    alt={`${item.name}`}
                    className="max-h-[35px] lg:max-h-[100%]"
                  />
                </div>
              </div>
            ))}
          </Marquee>
        </div>
        <Marquee direction="ltr">
          {companyItemsRow2.map((item, index) => (
            <div key={index}>
              <div className="flex justify-center items-center item lg:ps-[100px] lg:pe-[100px] ps-[30px] pe-[30px]">
                <img
                  src={item.logo}
                  alt={`${item.name}`}
                  className="max-h-[35px] lg:max-h-[100%]"
                />
              </div>
            </div>
          ))}
        </Marquee>
      </div>
      <div className="flex justify-center">
        <div className="text-white group w-max mt-[20px]">
          <Link
            className="font-medium text-white text-[16px] lg:text-xl text-center pt-12 group group-hover:text-white"
            href={localizePath("/about", locale)}
          >
            <CustomSourceflowText
              path={`dynamic1.${path}.cta_button_title_1`}
              locale={locale}
              title={cta_button_title_1}
            />
            <span className="w-0 bg-white h-[1px] transition-all block group-hover:w-full mt-[-10px]" />
          </Link>
        </div>
      </div>
      {isFooterExists && (
        <div className="max-w-[814px] flex flex-col pt-0 sm:pt-16 md:pt-36 3xl:max-w-[60%]">
          <span className="text-base/[20px] sm:text-xl/[35px] md:text-[27px]/[35px] pb-5">
            <CustomSourceFlowText
              title="We’ve been helping clients achieve the results they need for over 20 years. In that time, we’ve refined our services to provide an arsenal of tools to fill those hard-to-fill positions, to a timeline that suits you."
              path={`dynamic1.description.${path}`}
              locale={locale}
            />
          </span>
          <div className="flex">
            <div className="text-white group w-max">
              <Link
                className="font-medium text-white text-[16px] lg:text-xl text-center pt-12 group group-hover:text-white"
                href={localizePath("/retained-search", locale)}
              >
                <CustomSourceflowText
                  path={`dynamic1.${path}.cta_button_title_2`}
                  locale={locale}
                  title={cta_button_title_2}
                />
                <span className="w-0 bg-white h-[1px] transition-all block group-hover:w-full mt-[-10px]" />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
